@import "../../globals.scss";

.erp-progress-bar {
    background-color: transparent;
    min-width: 300px;

    .progress-bar {
        width: 100%;
        height: 25px;
        background-color: $grey;
        border-radius: 50px;

        overflow: hidden;

        .progress {
            background-color: $primaryColor;
            border-radius: 50px;
            height: 100%;
            transition: width 0.25s ease-in-out;

            display: flex;
            justify-content: center;

            .label-container {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;

                color: $white;
                font-weight: bold;
                font-size: 1rem;
                text-align: center;
            }
        }
    }
}
