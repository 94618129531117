//globals.scss

@import "./fonts.scss";

$primaryColor: #08bfdd;
$primaryColorLt: #5ce3f9;
$secondaryColor: #ff671b;
$secondaryColorLt: #ff894e;
$alert: #d2272e;
$alertLt: #ef5459;
$warn: #f8952d;
$success: #77bc1f;
$success-2: #acd47f;
$yellow: #fdc607;
$yellow-2: #fae882;
$blue: #0076bb;
$light-blue: #86dbe74d;

$text-primary: #77777a;
$text-input: #333;

// Monochromes
$black-1: #252628;
$black-2: #000002;
$dark-grey: #77777a;
$grey: #acacae;
$light-grey-1: #dcdcdc;
$light-grey-2: #e7e7e7;
$light-grey: #e3e4e5;
$disabled-grey: #efefef;
$disabled-grey-off: #d1d3d4;
$off-white: #f7f7f8;
$white: #ffffff;

// Font stack
$font-stack: "Lato", sans-serif !default;

// Font-weights
$light: 300;
$regular: 400;
$semi-bold: 600;
$bold: 700;
$black: 900;
