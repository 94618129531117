@import "../../globals.scss";

.sort-header {
    cursor: pointer;

    div {
        display: flex;
    }

    &.centered {
        text-align: center;

        div {
            justify-content: center;
        }
    }
}

.header--sort {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    :first-child {
        margin-bottom: -10px;
    }

    svg {
        color: $text-primary;
        opacity: 1;

        &.not-active {
            opacity: 0.5;
        }
    }
}
