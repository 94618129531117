@import "../../globals.scss";
@import "../../mixins.scss";

.system-container {
    background-color: $white;
    height: 100%;
    overflow: auto;

    .system-section {
        padding: 20px 0;
        margin: 0 30px;
        border-bottom: 1px solid $light-grey;
    }

    .system-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;

        h5 {
            margin: 0;
        }

        .system-header-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .saved-status-icon {
            color: $success;
        }

        @include md {
            flex-direction: row;
        }
    }

    .resource-locked-warning {
        margin: 10px 30px;
    }

    form {
        .system-info-container {
            .system-info {
                max-width: 100%;
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                margin: auto;

                .form-group {
                    width: 100%;
                }

                @include lg {
                    max-width: 50%;
                }
            }
        }
    }

    .locations-and-scenarios-containers {
        border-bottom: none;

        .action-button-group {
            display: flex;
            flex-direction: row;
            gap: 10px;
            margin-bottom: 10px;
            float: right;
        }

        .locations-and-scenarios {
            display: flex;
            flex-direction: column;
            width: 100%;

            .scenario-picker {
                width: 100%;
                display: flex;
                flex-direction: row;

                .scenario-picker--scenarios {
                    max-height: 300px;
                    overflow-x: auto;
                    overflow-y: hidden;
                    display: flex;
                    flex-direction: row;

                    .scenario-picker--scenario {
                        padding: 10px;
                        cursor: pointer;
                        width: 200px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .scenario-picker-name {
                            color: $text-primary;
                            font-size: 18px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            white-space: nowrap;

                            &:hover {
                                text-overflow: clip;
                                white-space: normal;
                                word-break: break-all;
                            }
                        }

                        &.active {
                            background-color: $off-white;
                            .scenario-picker-name {
                                color: $primaryColor;
                                font-weight: 700;
                            }
                        }

                        &:hover {
                            background-color: $off-white;

                            .scenario-picker-name {
                                font-weight: 700;
                            }
                        }

                        .scenario-picker--scenario-actions {
                            color: $text-primary;
                            display: flex;
                            flex-direction: row;

                            svg {
                                margin: 0 5px;
                            }
                        }
                    }
                }

                .new-scenario-button {
                    padding: 5px;
                    button {
                        width: inherit;
                    }
                }
            }

            .scenario {
                width: 100%;
                height: 100%;
                overflow-x: auto;
                overflow-y: hidden;

                .scenario-empty {
                    background-color: $off-white;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-height: 325px;
                }

                .scenario-locations {
                    background-color: $off-white;
                    height: 100%;
                    padding: 10px;
                    min-height: 325px;
                    display: flex;
                    flex-direction: column;
                    overflow-x: auto;

                    .locations {
                        display: flex;
                        flex-direction: row;
                        flex: 1;

                        .scenario-location-card {
                            padding: 10px;
                            min-width: 500px;
                            max-width: 500px;

                            &.placeholder-card {
                                min-width: 300px;
                                max-width: 300px;
                            }
                        }

                        .scenario-location {
                            text-align: center;
                            border-right: 1px solid $grey;

                            .location-name {
                                font-size: 16px;
                                font-weight: 700;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                margin-bottom: 20px;
                                border-bottom: 2px solid#e3e4e5;
                                padding-bottom: 8px;

                                &:hover {
                                    text-overflow: clip;
                                    white-space: normal;
                                    word-break: break-all;
                                }
                            }

                            .scenario-location--dnd {
                                .dropzone {
                                    height: auto;
                                    min-height: 150px;
                                }
                            }
                        }
                    }

                    .default-scenario-enable-checkbox {
                        margin: 10px;
                    }
                }

                .scenario-description {
                    margin-top: 20px;
                    max-width: 100%;

                    @include lg {
                        max-width: 75%;
                    }
                }

                .scenario-observed-data {
                    .enable-observed-data-checkbox {
                        margin: 10px;
                        margin-left: 0;
                    }

                    .observed-data-actions {
                        display: flex;
                        flex-wrap: wrap;
                        flex-direction: column;

                        .scenario-refresh-button {
                            margin-bottom: 15px;
                            width: 200px;
                        }

                        .refresh-status {
                            align-items: flex-start;
                            padding-left: 0;
                        }
                    }
                }
            }

            @include lg {
                flex-direction: row;

                .scenario-picker {
                    flex-direction: column;
                    max-width: 250px;
                    min-width: 250px;
                    justify-content: space-evenly;

                    .scenario-picker--scenarios {
                        overflow-x: hidden;
                        overflow-y: auto;
                        width: auto;
                        flex-direction: column;

                        .scenario-picker--scenario {
                            width: unset;
                        }
                    }

                    .new-scenario-button {
                        margin-top: 20px;
                        width: 100%;
                        padding: 0;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}

.system-loading {
    height: 100%;
}

.scenario-modal {
    .erp-checkbox-container {
        margin-bottom: 20px;
    }
}

.data-preview-modal {
    padding: 0 !important;
}

.data-preview-modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    margin: 0 !important;
}

.data-preview-modal-content {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
}

.data-preview-modal-body {
    .no-data-message {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        text-align: center;
        align-items: center;
        font-size: 20px;
    }
}

.data-preview-options {
    display: flex;
    flex-direction: column;
    padding: 10px 0 10px 0;
    gap: 30px;
    position: relative;
    z-index: 999;

    .select {
        width: auto;
        flex: 1;
    }

    @include lg {
        flex-direction: row;
    }
}

.data-preview--navigation {
    margin-bottom: 10px;

    .data-preview--tabs {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        background: $primaryColor;
        padding: 5px;

        .data-preview--tab {
            flex: 1;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            button {
                width: 100%;
                height: 40px;
                color: white;
                background-color: $primaryColor;

                &:hover {
                    &:not(.active) {
                        background-color: $primaryColorLt;
                    }
                }

                &.active {
                    background-color: $primaryColorLt;
                }
            }
        }

        @include lg {
            flex-direction: row;

            .data-preview--tab {
                margin-bottom: 0;
                margin-left: 0;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.timeseries-axis--navigation {
    margin-right: 15px;

    .timeseries-axis--tabs {
        display: flex;
        flex-direction: row;

        .timeseries-axis--tab {
            flex: 1;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            button {
                width: 100%;
                height: 35px;
                color: white;
                background-color: $grey;

                &:hover {
                    &:not(.active) {
                        background-color: $primaryColorLt;
                    }
                }

                &.active {
                    background-color: $primaryColor;
                }
            }
        }
    }
}

.bulk-import-modal-body {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .info-panel {
        display: flex;
        flex-direction: row;
        gap: 10px;
        border: 3px dashed $blue;
        border-radius: 5px;
        background-color: $light-blue;
        padding: 10px;

        .info-icon {
            color: $blue;
            margin: auto;
        }

        .info-text {
            color: black;
            font-weight: 400;
            font-size: 13px;
        }
    }

    .naming-convention-container {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .select {
            width: 200px;
        }

        .naming-convention-menu {
            display: flex;
            flex-direction: row;
            gap: 5px;

            .naming-convention-text {
                display: inline-block;
                align-self: flex-end;

                font-size: 20px;
                font-weight: 500;
            }
        }

        .additional-settings {
            display: flex;
            flex-direction: row;
        }
    }

    .file-uploader {
        .dropzone {
            height: auto;
            min-height: 200px;
        }
    }

    .import-progress-panel {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .import-console {
            display: flex;
            flex-direction: column;
            gap: 10px;
            border: 1px solid $grey;
            padding: 5px;
            height: 200px;
            overflow: auto;

            .console-message {
                display: flex;
                flex-direction: row;
                gap: 5px;

                align-items: center;

                .error-icon {
                    color: $alert;
                }

                .success-icon {
                    color: $success;
                }

                .warn-icon {
                    color: $warn;
                }
            }
        }
    }
}
