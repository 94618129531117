@import "../../globals.scss";
@import "../../mixins.scss";

.file-selector-btn {
    button {
        font-size: 20px;
        padding: 4px 15px;

        svg {
            margin-left: 10px;
        }
    }
}

.dropzone-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 150px;

    align-items: center;
    justify-content: center;

    padding: 20px;

    margin-top: 2em;

    border: 2px dashed $dark-grey;
    border-radius: 2px;

    background-color: $white;
    color: $text-primary;

    &:hover {
        cursor: default;
    }

    .dropzone-label {
        display: flex;
        flex-direction: row;
        gap: 5px;

        color: $grey;
        font-weight: 300;
        font-size: 20px;

        .clickable-label {
            color: $primaryColor;
            font-weight: 500;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    &.disabled {
        background-color: $disabled-grey;

        &:hover {
            cursor: default;
        }
    }
}

.data-connection-modal-backdrop {
    z-index: 1050 !important;
}

.data-connection-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .option-field {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .erp-label {
            margin-bottom: 0;
        }

        .label-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .select {
            width: 200px;
        }
    }

    .general-settings-container {
        display: flex;
        flex-direction: row;
        gap: 30px;
    }

    .data-settings-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 30px;

        .option-field {
            width: 100%;

            .select {
                width: 100%;
            }
        }
    }

    .chart {
        margin-bottom: 0;
    }
}

.data-connection-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .test-connection-container {
        display: flex;
        flex-direction: row;
        gap: 10px;

        align-items: center;

        .success-icon {
            color: $success;
        }

        .error-icon {
            color: $alert;
        }
    }
}
