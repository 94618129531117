/* Lato */

@font-face {
    font-family: "Lato";
    src: url("/Assets/fonts/lato/Lato-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}
@font-face {
    font-family: "Lato";
    src: url("/Assets/fonts/lato/Lato-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}
@font-face {
    font-family: "Lato";
    src: url("/Assets/fonts/lato/Lato-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}
@font-face {
    font-family: "Lato";
    src: url("/Assets/fonts/lato/Lato-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}
@font-face {
    font-family: "Lato";
    src: url("/Assets/fonts/lato/Lato-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 900;
}
