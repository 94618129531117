@import "../../globals.scss";

.slider-title {
    margin-top: 5px;
    padding-right: 20px;
}

.slider-holder {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.slider-value {
    max-width: 100px;
    margin: 0;
    text-align: center;
}

.erp-slider {
    width: 100%;
    margin: 0 5px 0 5px;
}

.track {
    height: 10px;
    top: 15px;
    background: $grey;
}

.thumb {
    top: 8px;
    width: 7px;
    height: 25px;
    background-color: $secondaryColor;
    cursor: grab;
}

.track.track-1 {
    background: $primaryColor;
}

.mark {
    cursor: pointer;
    top: 11px;
    width: 1px;
    height: 18px;
    background-color: $light-grey !important;

    .mark-label {
        position: relative;
        top: 15px;
    }
}

.mark-active {
    background-color: $primaryColorLt !important;
}
