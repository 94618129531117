.erp-numeric-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    input {
        margin-right: 5px;
        &.with-unit{
            padding-right: 75px;
        }
    }

    .unit {
        position: absolute;
        right: 0;
        padding-right: 15px;
        max-width: fit-content;
    }
}
