@import "../../globals.scss";

.modal--header {
    align-items: center !important;

    .header-title {
        display: flex;
        flex: 1;
        justify-content: center;
        font-size: 25px;
        color: $primaryColor;
        font-weight: 600;
    }

    svg {
        font-size: 30px;
        cursor: pointer;
    }
}
