@import "../../globals.scss";

.erp-button {
    color: white;
    border-radius: 3px;
    font-size: 1rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
    min-width: 50px;
    padding: 5px 15px;
    cursor: pointer;
    font-weight: 600;

    svg {
        &.btn-icon-before {
            margin-right: 10px;
        }

        &.btn-icon-after {
            margin-left: 10px;
        }
    }

    &.primary {
        background: $primaryColor;
        border: 2px solid $primaryColor;

        &:hover {
            background: $primaryColorLt;
            border: 2px solid $primaryColorLt;
        }

        &:disabled {
            background: $light-grey;
            border: 2px solid $light-grey;
            color: $grey;
            opacity: 0.8;
            cursor: initial;
        }
    }

    &.secondary {
        background: $secondaryColor;
        border: 2px solid $secondaryColor;
        color: $white;

        &:hover {
            background: $secondaryColorLt;
            border: 2px solid $secondaryColorLt;
            color: $white;
        }

        &:disabled {
            background: $light-grey;
            border: 2px solid $light-grey;
            color: $grey;
            opacity: 0.8;
            cursor: initial;
        }
    }

    &.grey {
        background: $grey;
        border: 2px solid $grey;

        &:hover {
            background: $dark-grey;
            border: 2px solid $dark-grey;
        }

        &:disabled {
            background: $light-grey;
            border: 2px solid $light-grey;
            color: $grey;
            opacity: 0.8;
            cursor: initial;
        }
    }

    &.none {
        background: unset;
        border: unset;
        color: $text-primary;

        &:disabled {
            color: $grey;
            opacity: 0.8;
            cursor: initial;
        }
    }

    &.rounded {
        border-radius: 0.25rem;
    }

    &.reverse {
        color: $primaryColor;
        border: 2px solid $white;
        background-color: $white;

        &:hover {
            background-color: $primaryColor;
            border: 2px solid $primaryColor;
            color: $white;
        }
    }

    &.activable-button {
        background-color: $light-grey;
        font-weight: 600;
        border-radius: 0;

        &.active {
            background-color: $primaryColor;
            color: $white;

            svg {
                color: $white;
            }
        }

        &:hover {
            &:not(.active) {
                background-color: $grey;
                color: $white;

                svg {
                    color: $white;
                }
            }
        }

        svg {
            margin-left: 10px;
            color: $text-primary;
            vertical-align: middle;
        }
    }

    &.attention {
        background: $alert;
        border: 2px solid $alert;

        &:hover {
            background: $alertLt;
            border: 2px solid $alertLt;
        }

        &:disabled {
            background: $light-grey;
            border: 2px solid $light-grey;
            color: $grey;
            opacity: 0.8;
            cursor: initial;
        }
    }
}
