@import "../../globals.scss";

.progress-modal--body {
    text-align: center;

    .progress-modal--label {
        margin-bottom: 10px;
    }

    .file-upload-progress {
        margin: 10px;
    }
}
