@import "../../globals.scss";
@import "../../mixins.scss";

.plugin-definition-groups {
    &.bordered-definition-group {
        border-top: 1px solid $light-grey;
        padding-top: 20px;
    }

    .plugin-definition-groups--header {
        margin-bottom: 15px;
    }
}

.plugin-definition {
    margin: 10px 0;

    .plugin-definition--header {
        font-size: 15px;
        font-weight: bold;
    }

    &.waterhole-calibrate-definition {
        margin-top: 25px;
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .plugin-definition-fields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .plugin-definition-field {
            min-width: 100%;
            margin: 10px;
            margin-left: 0;

            .plugin-definition-field-label {
                font-weight: 500;
                font-size: 14px;
            }

            &.ari {
                min-width: 100%;
            }

            &.predictors-select {
                min-width: 100%;
            }

            &.curve {
                min-width: 100%;
            }

            &.flow-type {
                min-width: 100%;
            }

            &.season-type {
                min-width: 100%;
            }

            &.spell-type {
                min-width: 100%;
            }

            &.velocity-calculation {
                min-width: 100%;
            }

            &.velocity-adjustment {
                min-width: 100%;
            }

            .ari-parameter-field {
                display: flex;
                flex-direction: column;

                .ari-radio-button-group {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    margin-bottom: 20px;

                    .ari-radio-button {
                        width: auto;
                        margin-right: 20px;
                    }
                }

                .ari-group {
                    gap: 20px;

                    .ari-data-checkbox {
                        margin-top: 20px;
                    }
                }
            }

            .threshold-parameter-field {
                display: flex;
                flex-direction: column;
                width: 100%;

                .threshold-label {
                    font-weight: 500;
                    font-size: 14px;
                }

                .threshold-ari-parameter-field {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .threshold-ari-radio-button-group {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;

                        .threshold-ari-radio-button {
                            width: auto;
                        }
                    }

                    .threshold-ari-group {
                        display: flex;
                        flex-direction: row;

                        .threshold-ari-input {
                            width: 60%;
                        }

                        .threshold-ari-data-checkbox {
                            margin-left: 20px;
                            margin-top: 0;
                        }
                    }

                    .threshold-threshold-input {
                        width: 100%;
                    }
                }
            }

            .spell-type-parameter-field {
                display: flex;
                flex-direction: column;
                gap: 30px;
                margin-bottom: 10px;
            }

            .season-type-parameter-field {
                display: flex;
                flex-direction: column;

                .season-type-radio-button-group {
                    display: flex;
                    flex-direction: row;

                    .season-type-radio-button {
                        width: auto;
                        margin-right: 20px;
                    }
                }

                .season-select-group {
                    .season-select {
                        display: flex;
                        flex-direction: column;

                        .season-select-label {
                            font-weight: 500;
                            font-size: 14px;
                            margin: 10px 0 10px 0;
                        }

                        .season-select-inputs {
                            display: flex;
                            flex-direction: row;
                            gap: 10px;

                            .season-select-day {
                                margin-right: 10px;
                                min-width: 90px;
                            }

                            .season-select-month {
                                width: 100%;
                                min-width: 140px;
                                margin-right: 20px;
                            }
                        }
                    }

                    .allow-partial-seasons {
                        margin-top: 20px;
                    }
                }

                .temperature-select-group {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    .temperature-threshold-group {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        margin-top: 10px;
                        align-items: center;

                        .temperature-comparison-select {
                            width: 30%;
                        }

                        .temperature-threshold {
                            width: 70%;
                        }
                    }

                    .temperature-moving-average-group {
                        display: flex;
                        flex-direction: row;

                        .temperature-use-moving-average-checkbox {
                            width: 50%;
                        }

                        .temperature-moving-average-input {
                            width: 50%;
                        }

                        gap: 10px;
                    }
                }
            }

            .flow-type-parameter-field {
                display: flex;
                flex-direction: column;

                .flow-type-radio-button-group {
                    display: flex;
                    flex-direction: row;

                    .flow-type-radio-button {
                        width: auto;
                        margin-right: 20px;
                    }
                }

                .flow-type-input {
                    width: 100%;
                    margin-top: 20px;
                }
            }

            .checkbox-value-parameter-field {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }

            .numeric-range-parameter-field {
                display: flex;
                flex-direction: column;

                .numeric-range-values {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;

                    .numeric-range-value-input {
                        display: flex;
                        flex-direction: column;

                        .numeric-range-input-label {
                            font-weight: 400;
                            font-size: 12px;
                            margin: 0;
                        }
                    }
                }
            }

            .risk-categories-parameter-field {
                display: flex;
                flex-direction: column;

                .risk-categories-input {
                    input {
                        padding-right: 0;
                        padding-left: 50px;
                    }

                    .unit {
                        left: 0;
                        padding-right: 0;
                        padding-left: 15px;
                    }

                    &.risk-categories-low {
                        input {
                            padding-left: 50px;
                        }
                    }
                }
            }

            .assessment-parameter-field {
                display: flex;
                flex-direction: column;

                .assessment-params {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .assessment-select {
                        width: 100%;
                        padding: 10px 0;
                    }

                    .assessment-select-month {
                        width: 100%;
                        padding: 10px 0;
                    }

                    .assessment-button-group {
                        .assessment-radio-button {
                            width: auto;
                        }
                    }

                    @include md {
                        flex-direction: row;
                        padding-right: 50px;
                        .assessment-select {
                            width: 130px;
                            padding: 0 10px;
                        }
                        .assessment-select-month {
                            width: 170px;
                            padding: 0 10px;
                        }
                    }
                }
            }

            .season-select-parameter-field {
                display: flex;
                flex-direction: column;

                .season-select-parameter-field--inputs {
                    display: flex;
                    flex-direction: row;

                    .season-select-day {
                        margin-right: 10px;
                        min-width: 90px;
                    }

                    .season-select-month {
                        width: 100%;
                    }

                    .season-select-year {
                        width: 100%;
                        margin-left: 10px;
                    }
                }
            }

            .curve-parameter-field-container {
                display: flex;
                flex-direction: column;
                width: 100%;

                .curve-parameter-field {
                    width: 100%;

                    .curve-parameter-field--table {
                        width: 100%;
                        text-align: center;

                        tr {
                            border-bottom: 1px solid $grey;
                            position: relative;

                            td {
                                padding: 10px 0;

                                &.curve-parameter-field--table-value {
                                    width: 50%;

                                    .editable-span {
                                        cursor: pointer;
                                        border-bottom: 2px solid $primaryColor;
                                        line-height: 1.5;
                                    }

                                    .table-value-edit-container {
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: center;

                                        .table-value-input-container {
                                            justify-content: center;
                                            margin-right: 0;

                                            .table-value-input {
                                                width: 60px;
                                                padding: 0 5px;
                                                height: 24px;
                                            }
                                        }

                                        svg {
                                            cursor: pointer;
                                        }
                                    }
                                }
                            }

                            &.curve-parameter-add-row {
                                border-bottom: none;

                                svg {
                                    cursor: pointer;
                                }
                            }

                            .remove-row-icon {
                                position: absolute;
                                top: 15px;
                                right: 5px;
                                cursor: pointer;
                            }
                        }
                    }

                    .curve-parameter-import-button {
                        margin-top: 20px;
                    }
                }

                .curve-parameter-graph-container {
                    width: 100%;
                    padding: 10px;
                    height: 350px;
                }

                @include md {
                    flex-direction: row;

                    .curve-parameter-field {
                        width: 30%;
                    }

                    .curve-parameter-graph-container {
                        width: 70%;

                        height: auto;
                    }
                }
            }

            .velocity-calculation-parameter-field {
                display: flex;
                flex-direction: column;
                gap: 25px;

                .width-roughness-fields {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;

                    .velocity-calculation-field {
                        min-width: 33%;
                    }
                }

                .cross-section-fields {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .velocity-calculation-field {
                        min-width: 15%;
                    }
                }

                @include md {
                    flex-direction: row;

                    .velocity-calculation-method-select {
                        width: 20%;
                    }

                    .width-roughness-fields {
                        width: 80%;
                        justify-content: space-between;
                    }

                    .cross-section-fields {
                        flex-direction: row;
                        width: 80%;
                        justify-content: space-between;
                    }
                }
            }

            .velocity-adjustment-parameter-field {
                display: flex;
                flex-direction: column;
                gap: 25px;

                .velocity-adjustment-fields {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;

                    .velocity-adjustment-field {
                        width: 33%;
                    }
                }

                @include md {
                    flex-direction: row;

                    .velocity-adjustment-checkbox {
                        width: 20%;
                    }

                    .velocity-adjustment-fields {
                        width: 80%;
                    }
                }
            }

            .plugin-parameter-checkbox {
                height: 100%;
            }

            @include md {
                min-width: 32%;

                .ari-parameter-field {
                    .ari-radio-button-group {
                        flex-direction: row;
                        gap: 20px;

                        .ari-radio-button {
                            width: auto;
                        }
                    }

                    .ari-group {
                        display: flex;
                        flex-direction: row;

                        .ari-input {
                            width: 30%;
                        }

                        .ari-data-checkbox {
                            margin-left: 20px;
                            margin-top: 0;
                        }
                    }

                    .threshold-input {
                        width: 30%;
                    }
                }

                .threshold-parameter-field {
                    width: 35%;

                    .threshold-ari-parameter-field {
                        .threshold-ari-group {
                            .threshold-ari-input {
                                width: 40%;
                            }
                        }
                    }
                }

                .spell-type-parameter-field {
                    flex-direction: row;
                    gap: 45px;
                    margin-bottom: 20px;

                    .spell-type-select {
                        width: 20%;
                    }
                }

                .season-type-parameter-field {
                    .season-type-radio-button-group {
                        display: flex;
                        flex-direction: row;
                    }

                    .season-select-group {
                        display: flex;
                        flex-direction: row;
                        margin-top: 10px;

                        .season-select {
                            display: flex;
                            flex-direction: column;

                            .season-select-label {
                                margin: 0;
                            }
                        }

                        .season-type-radio-button {
                            width: auto;
                        }
                    }

                    .temperature-select-group {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;

                        .temperature-threshold-group {
                            width: 32%;
                        }

                        .temperature-moving-average-group {
                            width: 32%;
                            margin-top: 10px;
                        }
                    }
                }

                .flow-type-parameter-field {
                    flex-direction: row;
                    align-items: center;
                    flex: 1;

                    .flow-type-radio-button-group {
                        .flow-type-radio-button {
                            width: auto;
                        }
                    }

                    .flow-type-input {
                        width: 33%;
                        margin-left: 20px;
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .waterhole-site-data-upload {
        .dropzone {
            height: 250px;
        }
    }

    .model-location-site--data {
        width: 500px;
    }
}

.waterhole-optimisation-modal {
    padding: 0 !important;
}

.waterhole-optimisation-modal-dialog {
    max-width: 100% !important;
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
    margin: 0 !important;
}

.waterhole-optimisation-modal-content {
    width: 100% !important;
    height: 100% !important;
    max-height: 100% !important;
}

.waterhole-optimisation-modal-body {
    display: flex;
    flex-direction: column;

    .calibration-modal-body {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        gap: 20px;

        .settings {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 20px;
        }

        .run-period {
            .run-period-select {
                padding: 10px 0 10px 0;
            }
        }
    }

    .calibration-optimisation-fields {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;

        .fields {
            th {
                padding-right: 10px;
                text-align: center;
            }

            td {
                padding: 5px 10px;
            }

            .field {
                max-width: auto;
            }

            .field-label {
                text-align: right;
            }
        }

        .results {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            .result {
                display: flex;
                flex-direction: column;
                gap: 20px;
                min-width: 100%;

                .field {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }

                .field-label {
                    margin-bottom: 0px;
                }
            }

            .calibration-charts {
                margin-top: 10px;

                .toggle-button {
                    margin-top: 10px;
                }

                .bathymetry {
                    display: flex;
                    flex-direction: column;
                    width: 90%;

                    .bathymetry-table {
                        margin: 20px;

                        th {
                            padding-right: 20px;
                        }

                        td {
                            padding: 5px 0px;
                        }
                    }

                    .bathymetry-chart {
                        width: 100%;
                        margin-bottom: 80px;
                    }
                }

                .calibration-chart {
                    width: auto;
                    padding-top: 0px;
                    display: block;
                    margin-bottom: 30px;

                    .chart-tooltip {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .run-to-empty-modal-body {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        gap: 20px;

        .settings {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .evaporation {
                .type {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
                margin-bottom: 10px;
            }

            .depth {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
            }
        }

        .run-period {
            .run-period-select {
                padding: 10px 0 10px 0;
            }
        }
    }

    .chart-tooltip {
        float: right;
        margin-left: 10px;
        margin-top: 10px;
    }

    .chart-tooltip-loading {
        float: right;
        margin-left: 10px;
        margin-top: 10px;
    }

    .run-to-empty-chart {
        width: auto;
        padding-top: 20px;
        display: block;
        margin-bottom: 30px;

        .chart-tooltip {
            margin-top: 0;
        }
    }

    .data-upload {
        .waterhole-optimisation-data-upload {
            .dropzone {
                height: 250px;
            }
        }

        .model-location-site-optimisation--data {
            width: 500px;
        }
    }

    @include sm {
        .run-to-empty-modal-body {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            .run-period {
                display: flex;
                flex-direction: row;
                gap: 20px;
            }

            .settings {
                display: flex;
                flex-direction: row;
                gap: 15px;
                align-items: center;

                .evaporation {
                    display: flex;
                    flex-direction: column;
                    width: auto;
                    margin-bottom: 0px;

                    .type {
                        display: flex;
                        flex-direction: row;
                        gap: 20px;

                        .select {
                            width: 180px;
                        }
                    }
                }

                .depth {
                    max-width: 150px;
                    width: auto;
                    margin-top: 10px;
                }
            }
        }

        .calibration-modal-body {
            width: 100%;
            display: flex;
            flex-direction: row;

            .run-period {
                display: flex;
                flex-direction: row;
                gap: 20px;
            }

            .settings {
                display: flex;
                flex-direction: row;
                gap: 15px;
                align-items: center;

                .field {
                    margin-top: 10px;
                    width: auto;

                    .select {
                        min-width: 150px;
                    }
                }
            }
        }

        .calibration-optimisation-fields {
            flex-direction: row;

            .fields {
                min-width: 500px;
            }

            .results {
                flex-direction: column;

                .result {
                    flex-direction: row;
                    margin-left: 15px;

                    .field {
                        flex-direction: row;
                        align-items: center;
                        width: auto;

                        .value {
                            min-width: 100px;
                        }
                    }
                }

                .calibration-charts {
                    .bathymetry {
                        display: flex;
                        flex-direction: row;
                    }
                }
            }
        }

        .data-upload {
            margin-top: 30px;
        }
    }
}

.waterhole-optimisation-modal-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .button-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    @include sm {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .button-group {
            display: flex;
            flex-direction: row;
            gap: 10px;
        }
    }
}
