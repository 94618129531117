@import "../../globals.scss";
@import "../../mixins.scss";

.model-container {
    background-color: $white;
    height: 100%;
    overflow: auto;

    .model-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
        margin: 0 30px;

        h5 {
            margin: 0;
            margin-bottom: 15px;
        }

        button {
            margin-left: 10px;
        }

        .model-header-actions {
            display: flex;
            flex-direction: column;
            align-items: center;

            .saving-indicator {
                margin: 0 10px 0 10px;
            }

            button {
                margin-top: 20px;
            }

            .computation-status {
                .completed-icon {
                    color: $success;
                }

                .warning-icon {
                    color: $warn;
                }

                .failed-icon {
                    color: $alert;
                }
            }
        }

        @include lg {
            flex-direction: row;

            h5 {
                margin: 0;
            }

            .model-header-actions {
                flex-direction: row;

                button {
                    margin-top: 0;
                    margin-left: 20px;
                }
            }
        }
    }

    .resource-locked-warning {
        margin: 10px 30px;
        margin-top: 0;
    }

    .model--navigation {
        margin: 0 30px;

        .model--tabs {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;

            .model--tab {
                flex: 1;
                margin-bottom: 10px;

                button {
                    width: 100%;
                    height: 50px;
                    font-size: 1.2rem;

                    svg {
                        margin-bottom: 2.5px;
                    }
                }

                .model--tab-warning {
                    color: $secondaryColor;
                }
            }

            @include lg {
                flex-direction: row;

                .model--tab {
                    margin-bottom: 0;
                    margin-left: 0;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .model--btn-navigation {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px 0;
        }
    }

    .explorer--navigation {
        margin-bottom: 10px;

        .explorer--tabs {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            background: $primaryColor;
            padding: 5px;

            .explorer--tab {
                flex: 1;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }

                button {
                    width: 100%;
                    height: 40px;
                    color: white;
                    background-color: $primaryColor;

                    &:hover {
                        &:not(.active) {
                            background-color: $primaryColorLt;
                        }
                    }

                    &.active {
                        background-color: $primaryColorLt;
                    }
                }
            }

            @include lg {
                flex-direction: row;

                .explorer--tab {
                    margin-bottom: 0;
                    margin-left: 0;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .model-general-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .model-attachments {
            padding: 20px 0;

            .attachments-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid $grey;
                padding: 10px 0;

                h5 {
                    margin: 0;
                }
            }

            .attachment-empty {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                min-height: 150px;
            }

            .model-attachment {
                padding: 20px 0;
                border-bottom: 1px solid $grey;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;

                &:last-child {
                    border-bottom: none;
                }

                .attachment-info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    max-width: 90%;

                    .attachment-thumbnail {
                        height: 200px;
                        min-width: 200px;
                        margin-bottom: 20px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.image-thumbnail {
                            img {
                                width: inherit;
                                height: inherit;
                            }
                        }

                        &.document-thumbnail {
                            background-color: $light-grey;
                            h4 {
                                margin-bottom: 0;
                            }
                        }
                    }

                    .attachment-labels {
                        display: flex;
                        flex-direction: column;
                        overflow: hidden;

                        .attachment-info--name {
                            font-size: 16px;
                            font-weight: 700;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            overflow: hidden;

                            &:hover {
                                text-overflow: clip;
                                white-space: normal;
                                word-break: break-all;
                            }
                        }

                        .attachment-info--date {
                            color: $grey;
                        }
                    }
                }

                .attachment-actions {
                    svg {
                        color: $primaryColor;
                        margin: 15px;
                        cursor: pointer;
                    }
                }

                @include sm {
                    flex-direction: row;

                    .attachment-info {
                        flex-direction: row;
                        width: auto;

                        .attachment-thumbnail {
                            height: 150px;
                            width: 200px;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }

    .model-settings-loader {
        margin: 50px;
    }

    .model-settings {
        .plugin-select {
            margin-bottom: 30px;
        }

        .plugin-loader {
            margin: 50px;
        }
    }

    .model-explore {
        max-width: none !important;
        margin: 0 30px !important;

        .results-warning {
            margin-bottom: 20px;
            background-color: $warn;
            color: white;
            padding: 10px;
            border-radius: 6px;

            span {
                margin-right: 10px;
            }
        }

        .results-message {
            display: flex;
            flex-direction: column;
            margin-top: 30px;
            text-align: center;
            align-items: center;
            font-size: 20px;

            button {
                margin-top: 10px;
            }
        }

        .explore-preselect {
            padding-top: 10px;

            .scenarios-table-container {
                display: flex;
                flex-direction: column;
                gap: 10px;

                .preselect-table {
                    width: 100%;

                    thead {
                        background-color: $off-white;
                    }

                    tr {
                        text-align: center;
                    }

                    td {
                        border-bottom: 1px solid $light-grey;

                        height: 70px;

                        color: $black-1;

                        .row-checkbox-container {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                        }
                    }

                    th {
                        padding: 10px;

                        .scenario-search-container {
                            display: flex;
                            flex-direction: column;
                            gap: 5px;

                            text-align: left;
                        }

                        .explore-select-container {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;

                            align-items: center;
                        }
                    }
                }

                .preselect-table-chart {
                    width: 100%;
                    height: 100%;

                    padding: 10px;

                    .container {
                        width: 100%;
                        height: 100%;

                        background: linear-gradient(#000, #000) no-repeat center/1px 100%;

                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;

                        .bar {
                            background-color: blue;
                            height: 60%;
                        }
                    }
                }
            }
        }

        .explore-summary {
            padding-top: 10px;

            .title {
                padding-bottom: 20px;
            }

            .risk-table {
                padding: 10px 0 30px 0;

                thead {
                    background-color: $light-grey;
                }

                tr {
                    height: 50px;
                    text-align: center;
                }

                td {
                    border-bottom: 1px solid;
                }
            }

            .gradient-bar-holder {
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 20px;

                .gradient-label {
                    margin-top: 3px;
                }

                .gradient-bar {
                    height: 30px;
                    width: 30%;
                    background-color: #acacae;
                    background-image: linear-gradient(to right, #ff5349, #fdc607, #08bfdd);
                }
            }
        }

        .chart {
            width: auto;
            padding-top: 20px;
            display: block;
            margin-bottom: 30px;

            .chart-tools {
                display: flex;
                flex-direction: row;

                .chart-options {
                    display: flex;
                    flex-direction: row;
                    margin-left: auto;

                    .chart-tooltip {
                        margin-top: 0;
                    }
                }
            }
        }

        .chart-legend {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .chart-legend-item {
                padding: 0 10px 0px 10px;
                display: flex;
                flex-direction: row;
                align-items: center;

                .legend-colour {
                    width: 40px;
                    height: 15px;
                    margin-right: 10px;

                    &.high {
                        background-color: #ff5349;
                    }
                    &.moderate {
                        background-color: #fdcd06;
                    }
                    &.low {
                        background-color: #08bfdd;
                    }
                    &.success {
                        background-color: #08bfdd;
                    }
                    &.failure {
                        background-color: #ff5349;
                    }
                    &.no-data {
                        background-color: #acacae;
                    }
                }

                .legend-text {
                    &.not-active {
                        text-decoration: line-through;
                    }
                }
            }
        }

        .explore-scenario-select {
            display: flex;
            flex-direction: column;
            padding: 10px 0 10px 0;
            gap: 30px;
            position: relative;
            z-index: 999;

            .scenario-select {
                width: auto;
                max-width: 50%;
                flex: 1;
            }

            @include lg {
                flex-direction: row;
            }
        }

        .explore-downloads {
            .downloads-header {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 15px;

                h5 {
                    margin-bottom: 0;
                }

                .download-all-button {
                    color: $primaryColor;
                }
            }

            .downloads-settings-container {
                display: flex;
                flex-direction: row;
                gap: 40px;

                padding: 15px 0 20px 0;

                .downloads-settings-header {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;

                    align-items: center;
                    cursor: pointer;

                    .downloads-settings-header-title {
                        font-size: 18px;
                    }

                    .downloads-settings-header-icon {
                        color: $primaryColor;
                    }
                }

                .downloads-settings-body {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;

                    .downloads-settings-option {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                }
            }

            .explore-downloads-scenario {
                .scenario-header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    background-color: $light-grey;
                    padding: 5px 10px;

                    .download-all-icon {
                        color: $primaryColor;
                        cursor: pointer;
                    }
                }

                .scenario-results-container {
                    margin: 15px 0;
                    display: flex;
                    flex-direction: column-reverse;
                    justify-content: space-between;

                    .scenario-results {
                        .scenario-result-download-button {
                            .scenario-result-download-icon {
                                color: $primaryColor;
                            }

                            .scenario-result-download-icon-loader {
                                color: $primaryColor;
                            }
                        }
                    }

                    .scenario-info {
                        margin: 0 15px;
                        margin-bottom: 10px;
                        display: flex;
                        flex-direction: column;

                        .scenario-info-label {
                            color: $grey;
                            margin-bottom: 10px;

                            .scenario-info-value {
                                color: $text-primary;
                                font-weight: bold;
                            }
                        }
                    }

                    @include lg {
                        flex-direction: row;
                        justify-content: space-between;

                        .scenario-info {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .explore-slider {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0 20px 0;
            padding-bottom: 3rem;
        }
    }

    .model-location-settings {
        .no-plugin-warning {
            background-color: $warn;
            color: $white;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 6px;
            font-weight: 500;

            svg {
                margin-right: 10px;
            }
        }

        .edit-system-link {
            text-align: end;
            margin-top: 5px;

            a {
                font-size: 18px;
                color: $primaryColor;
                text-decoration: underline;
            }
        }

        .system-loader {
            margin-top: 50px;
        }

        .model-location-settings--system {
            margin-top: 30px;

            .location-loader {
                margin-top: 50px;
            }

            .location-empty {
                text-align: center;
                margin-top: 50px;
                font-weight: 700;
            }

            .model-system-locations {
                margin-top: 10px;

                .model-system-location {
                    margin: 20px 0;

                    .model-system-location--header {
                        background-color: $light-grey;
                        padding: 10px 20px;
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        .model-system-location--header-title {
                            display: flex;
                            flex-direction: row;
                            align-items: center;

                            .header-title {
                                margin-left: 20px;
                                font-weight: 700;
                            }
                        }

                        .expand-icon {
                            color: $primaryColor;
                        }
                    }

                    .model-system-location--parameters {
                        padding: 20px 0;

                        .model-system-location-sites {
                            .model-system-location-sites--empty {
                                text-align: center;
                            }

                            .model-system-location-site {
                                margin-bottom: 20px;
                                padding-bottom: 20px;
                                border-bottom: 1px solid $light-grey;
                            }
                        }
                    }
                }
            }
        }
    }

    .model-location-settings-loader {
        margin: 50px;
    }

    .model-section-content {
        padding: 20px 0;
        margin: 0 30px;
        max-width: 1200px;

        @include xl {
            margin: auto;
        }
    }
}

.model-loading {
    height: 100%;
}

.model-scenario-empty {
    text-align: center;
}

.run-model-modal {
    display: flex;
    flex-direction: column;

    .toggle-all-button {
        margin-bottom: 15px;
        max-width: fit-content;
    }

    .scenarios-list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        max-height: 400px;
        overflow: auto;

        .model-scenario {
            .model-scenario--header {
                background-color: $light-grey;
                padding: 10px 20px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                .model-scenario--header-title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
                    width: 40%;

                    .switch-container {
                        display: flex;
                        width: 60px;
                        justify-content: center;
                    }

                    .header-title {
                        font-weight: 700;

                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        &:hover {
                            text-overflow: clip;
                            white-space: normal;
                            word-break: break-all;
                        }
                    }

                    @include lg {
                        width: 30%;
                    }
                }

                .model-scenario--header-icons {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    justify-content: space-between;
                    width: 60%;

                    .expand-icon {
                        color: $primaryColor;
                    }

                    .up-to-date-icon {
                        color: $success;
                    }

                    .outdated-icon {
                        color: $warn;
                    }

                    @include lg {
                        width: fit-content;
                    }
                }
            }

            .model-scenario--content {
                padding: 20px;

                .scenario-state-description {
                    margin-top: 20px;
                    border: 2px solid;
                    border-radius: 3px;
                    padding: 10px;
                    color: black;

                    &.up-to-date-description {
                        background-color: $success-2;
                        border-color: $success;
                    }

                    &.outdated-description {
                        background-color: $yellow-2;
                        border-color: $yellow;
                    }
                }
            }
        }
    }

    .model-scenario-empty {
        padding-bottom: 10px;
    }

    .computation-status {
        padding-left: 10px;
    }

    .infill-settings {
        display: flex;
        flex-direction: column;
        gap: 20px;

        padding-left: 10px;

        @include lg {
            flex-direction: row;

            .infill-type-select {
                width: 50%;
            }
        }
    }

    .run-period-container {
        .run-period-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: fit-content;

            padding: 0 10px;
            gap: 10px;

            cursor: pointer;

            .run-period-title {
                font-size: 20px;
            }

            .run-period-icon {
                color: $primaryColor;
            }
        }
    }
}

.plugin-update {
    margin-bottom: 20px;
    background-color: $warn;
    color: white;
    padding: 10px;
    border-radius: 6px;

    button {
        margin-left: 10px;
    }
}
