@import "../../globals.scss";
@import "../../mixins.scss";

.form-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    width: 100%;

    button {
        width: 100%;
        margin-bottom: 10px;
    }

    @include sm {
        flex-direction: row;

        button {
            width: auto;
            margin-bottom: 0;
            margin-right: 10px;
        }
    }
}
