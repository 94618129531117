@import "../../globals.scss";

.erp-checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: default;

    .erp-checkbox {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        max-width: 20px;
        max-height: 20px;
        border: 2px solid $primaryColor;
        background: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        &:hover {
            cursor: pointer;
        }

        svg {
            color: $primaryColor;
            font-size: 14px;
        }
    }

    .erp-checkbox-label {
        margin-left: 8px;
    }

    &.disabled {
        .erp-checkbox {
            background-color: $disabled-grey;
            border-color: $disabled-grey-off;
        }
    }
}
