@import "../../globals.scss";

.erp-icon--disabled {
    cursor: initial !important;
    opacity: 0.5 !important;
}

.system-icon {
    color: $primaryColor;
}

.model-icon {
    color: $secondaryColor;
}

.plugin-outofdate-icon {
    margin-left: 10px;
    margin-right: 10px;
    color: $secondaryColor;
}
