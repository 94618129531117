@import "../../globals.scss";
@import "../../mixins.scss";

.manage-account-container {
    background-color: $white;
    height: 100%;
    margin: 0 30px;

    .manage-account--tabs {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 20px 0;

        .manage-account--tab {
            flex: 1;
            margin-bottom: 10px;

            button {
                width: 100%;
            }
        }

        @include sm {
            flex-direction: row;

            .manage-account--tab {
                margin-bottom: 0;

                &:first-child {
                    margin-left: 0;
                    margin-right: 10px;
                }

                &:last-child {
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }
    }

    .change-password {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .change-password--inputs {
            display: flex;
            flex-direction: column;

            div {
                flex: 1;
                margin-bottom: 10px;
            }
        }

        .change-password--action {
            display: flex;
            justify-content: flex-start;

            button {
                width: 100%;

                svg {
                    margin-left: 5px;
                }
            }
        }

        @include sm {
            .change-password--inputs {
                flex-direction: row;

                div {
                    &:first-child {
                        margin-left: 0;
                        margin-right: 10px;
                    }

                    &:last-child {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }
            }

            .change-password--action {
                justify-content: flex-end;

                button {
                    width: auto;
                }
            }
        }
    }

    .manage-groups {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .manage-groups--header {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            h4 {
                margin-bottom: 10px;
            }

            button {
                width: 100%;

                svg {
                    margin-left: 5px;
                }
            }
        }

        .manage-groups--table {
            margin-top: 20px;

            table {
                width: 100%;

                thead {
                    tr {
                        th {
                            padding-bottom: 0.5rem;
                        }
                    }
                }

                tbody {
                    tr {
                        .group-name-cell {
                            svg {
                                margin-right: 10px;
                                color: $primaryColor;
                            }
                        }

                        .member-count-cell {
                            text-align: center;
                        }

                        .edit-cell {
                            text-align: right;
                        }

                        &.group-row {
                            cursor: pointer;
                            background-color: $off-white;
                            border: 1px solid $grey;

                            td {
                                padding: 0.75rem 1rem;
                                font-weight: 600;
                            }
                        }

                        &.group-row-users {
                            td {
                                padding: 10px;

                                .group-users-container {
                                    display: flex;
                                    flex-direction: column-reverse;
                                    justify-content: space-between;

                                    .group-users {
                                        display: flex;
                                        flex-direction: row;
                                        flex: 1;
                                        flex-wrap: wrap;
                                        align-items: flex-start;

                                        .group-user {
                                            display: flex;
                                            align-items: center;
                                            margin-right: 15px;

                                            svg {
                                                margin-right: 5px;
                                            }

                                            &.group-admin {
                                                svg {
                                                    color: $primaryColor;
                                                }
                                            }

                                            .group-user-remove {
                                                color: $text-primary !important;
                                                margin-left: 5px;
                                                cursor: pointer;

                                                &:hover {
                                                    color: $alert !important;
                                                }
                                            }
                                        }
                                    }

                                    .group-users-input {
                                        width: 100%;
                                        margin-bottom: 10px;

                                        .group-users-input--container {
                                            display: flex;

                                            .email-input {
                                                width: 100%;
                                                input {
                                                    border-top-right-radius: 0 !important;
                                                    border-bottom-right-radius: 0 !important;
                                                }
                                            }

                                            button {
                                                background-color: $primaryColor;
                                                border-color: $primaryColor;
                                                border-top-left-radius: 0;
                                            }

                                            .menu-toggle {
                                                border-bottom-left-radius: 0;

                                                &:after {
                                                    content: none !important;
                                                }
                                            }

                                            .menu-actions {
                                                padding: 0;

                                                button {
                                                    background-color: $white;
                                                    color: $primaryColor;
                                                    padding: 0.5rem 1rem;

                                                    &:hover {
                                                        background-color: $light-grey;
                                                        color: $dark-grey;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @include sm {
            .manage-groups--header {
                flex-direction: row;

                h4 {
                    margin-bottom: 0;
                }

                button {
                    width: initial;
                }
            }

            .manage-groups--table {
                table {
                    tbody {
                        tr {
                            &.group-row-users {
                                td {
                                    .group-users-container {
                                        flex-direction: row;

                                        .group-users-input {
                                            width: 400px;
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
