@import "../../globals.scss";
@import "../../mixins.scss";

.cross-section-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    .cross-section {
        width: 100%;
        overflow: auto;
        max-height: 500px;

        .cross-section--table {
            width: 100%;
            text-align: center;

            tr {
                border-bottom: 1px solid $grey;
                position: relative;

                td {
                    padding: 10px 0;

                    &.cross-section--table-value {
                        width: 50%;

                        .cross-section-editable-span {
                            cursor: pointer;
                            border-bottom: 2px solid $primaryColor;
                            line-height: 1.5;
                        }

                        .cross-section--table-value-edit-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;

                            .cross-section--table-value-input-container {
                                justify-content: center;
                                margin-right: 0;

                                .cross-section--table-value-input {
                                    width: 60px;
                                    padding: 0 5px;
                                    height: 24px;
                                }
                            }

                            svg {
                                cursor: pointer;
                            }
                        }
                    }
                }

                &.cross-section-add-row {
                    border-bottom: none;

                    svg {
                        cursor: pointer;
                    }
                }

                .remove-row-icon {
                    position: absolute;
                    top: 15px;
                    right: 5px;
                    cursor: pointer;
                }
            }
        }

        .cross-section-import-button {
            width: 100%;
        }
    }

    .cross-section-graph-container {
        width: 100%;
        padding: 10px;
        height: 350px;
    }

    @include md {
        flex-direction: row;

        .cross-section {
            width: 30%;
        }

        .cross-section-graph-container {
            width: 70%;

            height: auto;
        }
    }
}
