@import "../../globals.scss";

.data-file--container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;

    .data-file--info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 85%;
        padding-right: 5px;

        .data-type-select-menu {
            min-width: 160px;
        }

        .data-file--info-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover {
                text-overflow: clip;
                white-space: normal;
                word-break: break-all;
            }
        }
    }

    .data-file--icons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        width: 15%;

        .data-file--icon {
            cursor: pointer;

            &.remove-icon {
                color: $secondaryColor;
            }

            &.edit-icon {
                color: $primaryColor;
            }
        }
    }
}
