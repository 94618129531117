@import "../../globals.scss";

.erp-loading-indicator {
    display: flex;
    color: $primaryColor;

    &.centered {
        justify-content: center;
        align-items: center;
    }
}
