@import "../../globals.scss";

.file-system--breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 18px;

    .file-system--breadcrumbs-icon {
        color: $primaryColor;
        margin-right: 10px;
        cursor: pointer;

        &.disabled {
            color: $grey;
        }
    }

    span {
        color: $primaryColor;
        font-weight: 500;
        cursor: pointer;
    }

    .file-system--breadcrumbs-label {
        &:hover {
            text-decoration: underline;
        }

        &.current {
            text-decoration: none;
            color: $grey;
        }
    }

    .file-system--breadcrumbs-divider {
        margin: 0 8px;
        color: $grey;
    }
}
.fs-modal-body {
    padding: 0 !important;

    .fs-modal {
        .fs-modal-header {
            padding: 10px 20px;
            font-size: 20px;
            border-bottom: 1px solid $light-grey;

            svg {
                color: $primaryColor;
                margin-right: 10px;
            }
        }

        .fs-modal-items {
            min-height: 500px;
            max-height: 500px;
            overflow-x: hidden;
            overflow-y: auto;

            .fs-modal-loader {
                margin-top: 20px;
            }

            .fs-modal-folder,
            .fs-modal-system,
            .fs-modal-model {
                padding: 10px 20px;
                border-bottom: 1px solid $light-grey;
                font-size: 18px;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;

                svg {
                    margin-right: 10px;
                }

                &.disabled {
                    span,
                    svg {
                        opacity: 0.4;
                    }
                }
            }

            .fs-modal-system,
            .fs-modal-model {
                &.disabled {
                    span,
                    svg {
                        opacity: 0.4;
                    }
                }
                .fs-modal-system-select-icon,
                .fs-modal-model-select-icon {
                    color: $primaryColor;
                    margin-left: 10px;
                }
            }
        }
    }
}

.fs-modal-footer {
    display: flex !important;
    justify-content: center !important;
}

.fs-modal-backdrop {
    z-index: 1050 !important;
}
