@import "../../globals.scss";
@import "../../mixins.scss";

.erp-header {
    min-height: 75px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 30px;
    padding-left: 20px;
    justify-content: space-between;
    border-bottom: 1px solid $light-grey;

    .header-icon {
        height: 100%;
        display: none;

        img {
            height: 100%;
            width: 300px;
        }

        @include md {
            display: initial;
        }
    }

    .header-content {
        width: 100%;
        height: 100%;
        margin: 0 20px;
        display: flex;

        .header-label {
            display: flex;
            align-items: center;
            font-size: 20px;
            font-weight: 500;

            svg {
                margin-right: 20px;
            }

            .system-icon {
                color: $primaryColor;
            }

            .model-icon {
                color: $secondaryColor;
            }
        }

        @include md {
            &.explorer-header-content {
                justify-content: flex-end;
            }
        }

        .links {
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 30px;

            .link {
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
                max-width: 170px;
                width: auto;
                position: relative;
                cursor: pointer;
                text-decoration: none;
                color: $text-primary;
                margin: 0 20px;
                transition: color 0.15s ease-in-out;

                span {
                    display: none;
                    font-weight: 500;
                    font-size: 18px;
                }

                .link-indicator {
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    bottom: 0;
                    background-color: $primaryColor;
                }

                &:hover {
                    color: $primaryColor;
                    font-weight: 500;
                }

                @include sm {
                    svg {
                        margin-right: 20px;
                    }

                    span {
                        display: initial;
                    }
                }
            }
        }
    }

    .user-menu {
        .user-menu-dropdown-toggle {
            background-color: unset !important;
            border: unset !important;
            border-radius: unset !important;
            color: $text-primary !important;
            font-weight: 700 !important;
            outline: none !important;

            &::after {
                display: none;
            }
        }

        .user-menu-dropdown-menu {
            max-width: 300px;
            min-width: 250px !important;
            z-index: 1001 !important;

            a,
            .group {
                cursor: pointer;
                padding: 5px;
                color: $text-primary;
                margin-left: 0;
                font-size: 0.9rem;
                font-weight: 500;

                &:hover {
                    color: $text-primary;
                    text-decoration: none;
                    background-color: $light-grey;
                }
            }

            .name {
                margin: 8px;
                color: $text-primary;
            }

            .title {
                padding: 8px;
                color: $grey;
                font-weight: 700;

                h6 {
                    margin: 0;
                }
            }

            .groups {
                max-height: 200px;
                height: auto;
                overflow-y: auto;
                overflow-x: hidden;

                .group {
                    svg {
                        margin-left: 10px;
                    }

                    &.selected {
                        background-color: $primaryColor;
                        color: $white;
                    }
                }
            }

            @include sm {
                .groups {
                    max-height: 300px;
                }
            }
        }
    }
}
