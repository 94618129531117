@import "../../globals.scss";
@import "../../mixins.scss";

.task-status {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;

    .status-container {
        display: flex;
        flex-direction: column;
        gap: 5px;

        .status {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 5px;

            .status-label {
                padding: 0 5px;
                border-radius: 5px;
                margin-right: 5px;

                &.failed {
                    color: white;
                    background-color: $alert;
                }

                &.completed {
                    color: white;
                    background-color: $success;
                }

                &.running {
                    color: white;
                    background-color: $yellow;
                }

                &.pending {
                    color: white;
                    background-color: $warn;
                }
            }
        }
    }

    .task-error {
        margin-top: auto;
        margin-bottom: auto;
        text-align: left;
    }

    @include lg {
        align-items: flex-end;

        .status-container {
            flex-direction: row;
            align-items: center;
        }

        .task-error {
            text-align: right;
        }
    }
}
