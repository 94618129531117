@import "./globals.scss";

.app {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    letter-spacing: 0.02rem;

    .content {
        overflow: auto;
        height: 100%;
    }
}

.app-loader {
    height: 100%;
    background-color: $white;
    opacity: 1;
    transition: all 0.25s ease-in-out;

    svg {
        color: $primaryColor;
    }
}
