@import "../../globals.scss";

.explorer-container {
    background-color: $white;
    height: 100%;
    margin: 0 30px;

    .filter {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid $light-grey;

        .filter-header {
            margin-right: 20px;
        }

        .select {
            width: 200px;
        }
    }

    .explorer--header {
        display: flex;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid $light-grey;
        justify-content: space-between;

        .new-button {
            width: 100px;
            height: 38px;
            outline: none;
            background: $primaryColor;
            border: 2px solid $primaryColor;

            &:hover {
                background: $primaryColorLt;
                border: 2px solid $primaryColorLt;
            }

            &:focus {
                background: $primaryColorLt;
                border: 2px solid $primaryColorLt;
            }

            &::after {
                display: none;
            }

            .new-button-icon {
                margin-left: 10px;
            }
        }

        .new-button-menu {
            button {
                padding: 8px;

                svg {
                    margin-right: 20px;
                    margin-left: 10px;
                }
            }
        }

        .search-bar {
            margin: 0 10px;
            max-width: 800px;
        }
    }

    .explorer--breadcrumbs {
        padding: 20px 0;
        border-bottom: 1px solid $light-grey;
    }

    .explorer--table {
        width: 100%;

        .table--bulk-actions {
            display: flex;
            flex-direction: row;
            padding: 20px 0;
            border-bottom: 1px solid $light-grey;

            span {
                font-weight: bold;
            }

            .action-icons {
                display: flex;
                flex-direction: row;
                align-items: center;

                svg {
                    margin-left: 10px;
                    cursor: pointer;
                    color: $grey;
                }
            }
        }

        table {
            width: 100%;
            color: $text-primary;

            thead {
                tr {
                    border-bottom: 2px solid $light-grey-2;

                    th {
                        padding: 15px;
                        text-align: center;

                        &.selection-cell {
                            max-width: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                color: $primaryColor;
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 2px solid $light-grey-2;

                    td {
                        padding: 15px;

                        a {
                            text-decoration: none;
                            color: inherit;
                        }
                    }

                    &:hover {
                        background-color: $off-white;
                    }

                    .centered-cell {
                        text-align: center;
                    }

                    .status-cell {
                        color: $grey;
                    }

                    .action-cell {
                        text-align: end;

                        .action-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            font-size: 20px;
                            cursor: pointer;

                            svg {
                                margin-left: 10px;
                            }
                        }
                    }

                    .locked-icon {
                        margin-left: 10px;
                    }
                }
            }

            .folder-row {
                cursor: pointer;
            }
        }
    }

    .search--table {
        width: 100%;

        table {
            width: 100%;
            color: $text-primary;

            thead {
                tr {
                    border-bottom: 2px solid $light-grey-2;

                    th {
                        padding: 15px;
                        text-align: center;

                        &.selection-cell {
                            max-width: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                color: $primaryColor;
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-bottom: 2px solid $light-grey-2;

                    td {
                        padding: 15px;

                        a {
                            text-decoration: none;
                            color: inherit;
                        }
                    }

                    &:hover {
                        background-color: $off-white;
                    }

                    .centered-cell {
                        text-align: center;
                    }

                    .status-cell {
                        color: $grey;
                    }

                    .action-cell {
                        text-align: end;

                        .action-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            font-size: 20px;
                            cursor: pointer;

                            svg {
                                margin-left: 10px;
                            }
                        }
                    }

                    .locked-icon {
                        margin-left: 10px;
                    }
                }
            }

            .folder-row {
                cursor: pointer;
            }
        }
    }
}
