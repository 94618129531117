@import "../../globals.scss";

.erp-text-area {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    border: 1px solid $primaryColor !important;
    color: $text-primary !important;
    border-radius: 0 !important;
    font-weight: 400 !important;
    resize: none;

    &:focus {
        outline: none !important;
        border-color: $primaryColor !important;
        box-shadow: unset !important;
    }

    &.disabled {
        background-color: $disabled-grey;
        border-color: $disabled-grey-off !important;
    }
}
