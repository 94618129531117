@import "../../globals.scss";

.erp-input-container {
    display: flex !important;
    flex-direction: row !important;

    border: 1px solid $primaryColor !important;
    border-radius: 3px !important;
    font-weight: $regular !important;
    color: $text-input !important;

    &.disabled {
        background-color: $disabled-grey;
        border-color: $disabled-grey-off !important;
    }

    svg {
        &.btn-icon-before {
            margin-right: 10px;
            margin-top: 4px;
            color: $primaryColor;
        }
    }

    .erp-input {
        border: 0;
        width: 100%;

        &:focus {
            outline: none !important;
            border-color: $primaryColor !important;
            box-shadow: unset !important;
        }

        &::placeholder {
            color: $text-primary !important;
        }
    }
}
