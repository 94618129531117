.chart {
    width: auto;
    padding-top: 20px;
    display: block;
    margin-bottom: 30px;

    .chart-tools {
        display: flex;
        flex-direction: row;

        .chart-options {
            display: flex;
            flex-direction: row;
            margin-left: auto;

            .chart-tooltip {
                margin-top: 0;
            }
        }

        .chart-title {
            font-weight: 700;
            font-size: 16px;
        }
    }
}
