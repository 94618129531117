@import "../../globals.scss";

.resource-locked-warning {
    background-color: $warn;
    color: $white;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 6px;
    font-weight: 500;

    svg {
        margin-right: 10px;
    }
}
