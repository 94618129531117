.confirm-modal {
    padding: 8px;
}

.confirm-modal-backdrop {
    z-index: 1050 !important;
}

.confirm-modal--footer {
    display: flex;
    justify-content: center !important;
    align-items: center;
}
