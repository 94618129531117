@import "../../globals.scss";
@import "../../mixins.scss";

.run-period {
    .run-period-title {
        padding-left: 10px;
    }

    .run-period-select {
        padding: 10px;

        .run-period-select--inputs {
            display: flex;
            flex-direction: column;

            .run-period-select-day {
                margin-bottom: 10px;
                min-width: 120px;
            }

            .run-period-select-month {
                width: 100%;
                min-width: 180px;
                margin-bottom: 10px;
            }

            .run-period-select-year {
                width: 100%;
                min-width: 140px;
            }
        }
    }

    @include sm {
        .run-period-select {
            .run-period-select--inputs {
                flex-direction: row;

                .run-period-select-day {
                    margin-bottom: 0px;
                    margin-right: 10px;
                }

                .run-period-select-month {
                    margin-bottom: 0px;
                }

                .run-period-select-year {
                    margin-left: 10px;
                }
            }
        }
    }
}
